<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import AppButton from '@/components/ui/AppButton.vue';
import AppBackButton from '@/components/ui/AppBackButton.vue';
import IconShare from '@/components/ui/icons/IconShare.vue';
import IconArrowLeft from '@/components/ui/icons/IconArrowLeft.vue';

const route = useRoute();

const isCategoryPage = computed(() => route.name === 'Category');
const categoryName = computed(() => {
  return route.params.categoryName ? route.params.categoryName.replace(/-/g, ' ') : '';
});


const shareMessage = ref('');  // Initialize with an empty string or some default message

const buttonText = ref('Share Offers');

function onShareClick() {
    navigator.clipboard.writeText(window.location.href)
        .then(() => {
            buttonText.value = 'Link Copied!';
            setTimeout(() => buttonText.value = 'Share Offers', 2000);
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
}
</script>


<template>
    <div class="page-container">
        <nav class="nav-bar">
            <!-- Bread crumbs -->
            <h2 :class="['nav-title', { 'active': isCategoryPage }]">
                Recent Offers
                <!-- Display the category name if it exists -->
                <span v-if="categoryName"> / {{ categoryName }}</span>
            </h2>
            <span>{{ shareMessage }}</span>
            <a v-if="categoryName" href="/">
                <AppBackButton :text="'Back to Offers'" :icon="IconArrowLeft" />
            </a>
            <AppButton v-else :text="buttonText" :icon="IconShare" @click="onShareClick" />
        </nav>
        <h2 v-if="categoryName" class="page-title">"{{ categoryName }}"</h2>
    </div>
</template>
  
<style scoped lang="scss">
.page-container {
    width: 100%;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;
    width: 100%;

    .nav-title {
        color: #000;

        /* Desktop H2 */
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;

        &.active {
            font-weight: 300;
        }

        @media screen and (max-width: 660px) {
            font-size: 14px;
        }

    }

    a {
        text-decoration: none;
    }

    span {
        font-family: 'Lato';
        color: #000;
        font-size: 20px;
        font-weight: 600;

        @media screen and (max-width: 660px) {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 660px) {
        display: block;
        padding: 0 0 30px;
    }
}

.page-title {
    color: #000;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;

    @media screen and (max-width: 660px) {
        font-size: 26px;
    }
}
</style>
  