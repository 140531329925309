<script setup>
import { ref, markRaw, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const categoryName = computed(() => {
  // Check if the categoryName is defined, and then replace dashes with spaces
  return route.params.categoryName ? route.params.categoryName.replace(/-/g, ' ') : '';
});

// components
import OfferItem from '@/components/offers/OfferItem.vue';
// icons
import IconContact from '@/components/ui/icons/IconContact.vue';
import IconAvailability from '@/components/ui/icons/IconAvailability.vue';
import IconTour from '@/components/ui/icons/IconTour.vue';
import TheNavBar from '@/components/layout/TheNavBar.vue';
// images
import image1 from '@/assets/images/allora-bear-creek/week-1-cover.jpg';
import image2 from '@/assets/images/allora-bear-creek/week-2-cover.jpg';

// Mark icon components as raw to prevent them from being reactive
const IconContactRaw = markRaw(IconContact);
const IconAvailabilityRaw = markRaw(IconAvailability);
const IconTourRaw = markRaw(IconTour);

// New computed property for filtered and sorted offers
const filteredAndSortedOffers = computed(() => {
  if (!categoryName.value) {
    return sortedOffers.value;
  }
  return sortedOffers.value.filter(offer => offer.category.toLowerCase() === categoryName.value.toLowerCase());
});

const offersData = ref([
    {
        id: 1,
        date: 'Mar 27, 2024',
        category: 'Current Specials',
        title: 'Receive 1 month free and a $750 gift card when you lease today',
        description: "Our exclusive offer is designed to provide you with an unbeatable deal for all our available floor plans. Act now and get one month of rent for free, and as an additional bonus, we'll also give you a $750 gift card. This is a limited-time promotion, so don't hesitate to take advantage of this incredible opportunity to save big on your new apartment in Northwest Houston.",
        offerValid: 'Prices subject to availability.',
        imageUrl: image1,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: 'https://www.thelauraeastriver.com/contactus/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: 'https://www.thelauraeastriver.com/floorplans/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: 'https://www.thelauraeastriver.com/scheduletour/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' }
        ]
    },
    {
        id: 2,
        date: 'Mar 29, 2024',
        category: 'Availability Alert',
        title: 'Immediate move-ins available now in NW Houston',
        description: "Looking for a brand new apartment in NW Houston to live in but don't have the time to wait? Don't worry, we've got you covered! Our selection of move-in-ready apartments is available now, so you can settle into your new home as soon as possible. Enjoy the convenience and ease of a stress-free move with our immediate move-in options.",
        offerValid: 'Prices subject to availability.',
        imageUrl: image2,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: 'https://www.thelauraeastriver.com/contactus/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: 'https://www.thelauraeastriver.com/floorplans/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: 'https://www.thelauraeastriver.com/scheduletour/?utm_source=website&utm_medium=offer-feed&utm_campaign=feed-posts&utm_id=syndicate' }
        ]
    },
]);

// Function to parse and compare dates
const parseDate = (dateString) => new Date(dateString.toUpperCase());

// Computed property for sorted offers
const sortedOffers = computed(() => {
  return [...offersData.value].sort((a, b) => parseDate(b.date) - parseDate(a.date));
});

</script>
<template>
    <div class="offers-container">
        <TheNavBar />
        <!-- Use filteredAndSortedOffers for rendering offers -->
        <OfferItem v-for="offer in filteredAndSortedOffers" :key="offer.id" :offer="offer" />
    </div>
</template>

<style lang="scss" scoped>
.offers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
</style>