<script setup>
import {ref, onMounted} from 'vue';
import IconPet from "@/components/ui/icons/IconPet.vue";
import IconADA from "@/components/ui/icons/IconADA.vue";
import IconEHO from "@/components/ui/icons/IconEHO.vue";

const currentYear = ref(new Date().getFullYear());

onMounted(() => {
  currentYear.value = new Date().getFullYear();
});
</script>

<template>
    <footer class="footer">
        <!-- Top part of the footer for logo and icons -->
        <div class="footer-top">
            <div class="footer-branding">
                <div class="logo">
                    <img src="@/assets/images/the-laura/logo-the-laura.webp" alt="The Laura logo">
                </div>
                <div class="address-large">2810 Riverby Rd| Houston, TX 77020</div>
                <div class="address-small">2810 Riverby Rd<br /> Houston, TX 77020</div>
                <div class="disclaimer">Prices and special offers are subject to availability, expiration and other eligibility terms.</div>
            </div>
            <div class="footer-icons">
                <div class="icon">
                    <IconPet />
                </div>
                <div class="icon">
                    <IconADA />
                </div>
                <div class="icon">
                    <IconEHO />
                </div>
            </div>
        </div>
        <!-- Bottom part of the footer for copyright and powered by -->
        <div class="footer-bottom">
            <div class="copyright">
                ©{{ currentYear }} DYVERSE, LLC | All Rights Reserved | <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
            <a href="http://www.dyverse.com" target="_blank" class="powered">Powered by DYVERSE</a>
        </div>

        <div class="footer-bottom-small">
            <a href="http://www.dyverse.com" target="_blank" class="powered">Powered by DYVERSE</a>
            <div class="copyright">
                ©{{ currentYear }} DYVERSE | All Rights Reserved |
                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
            <div class="footer-icons-small">
                <div class="icon">
                    <IconPet />
                </div>
                <div class="icon">
                    <IconADA />
                </div>
                <div class="icon">
                    <IconEHO />
                </div>
            </div>
        </div>
    </footer>
</template>
  
<style lang='scss' scoped>
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #35302f;
    padding: 60px 27px 20px;

    /* Desktop Footer Copy */
    color: #9fbfb8;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;

    .footer-icons {
        display: flex;
        gap: 10px;

        // Mobile adjustments
        @media (max-width: 660px) {
            display: none;
        }
    }

    // Mobile adjustments
    @media (max-width: 660px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.footer-branding {
    flex-grow: 1;
    padding-left: 85px;

    // Mobile adjustments
    @media (max-width: 660px) {
        padding: 0;
    }
}

.logo {
    img {
        width: 15%;

        // Mobile adjustments
        @media (max-width: 660px) {
            width: 40%;
        }
    }
}

.address-large {
    margin-top: 10px;

    // Mobile adjustments
    @media (max-width: 660px) {
        display: none;
    }
}

.address-small {
    display: none;

    // Mobile adjustments
    @media (max-width: 660px) {
        display: block;
    }
}

.footer-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    // Mobile adjustments
    @media (max-width: 660px) {
        display: none;
    }
}

.footer-bottom-small {
    display: none;

    // Mobile adjustments
    @media (max-width: 660px) {
        display: block;
        font-size: 10px;
        margin-top: 50px;
    }

    .footer-icons-small {
        display: none;

        // Mobile adjustments
        @media (max-width: 660px) {
            display: flex;
            gap: 10px;
            justify-content: center;
            padding-top: 20px;
        }
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.disclaimer {
    display: block;
    font-size: 10px;
    opacity: 0.5;
    font-style: italic;
}

.powered {
    text-decoration: none;
}
</style>
  